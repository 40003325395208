import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/(authenticated)/admin/(index)": [22,[3]],
		"/(authenticated)/admin/account": [23,[3]],
		"/(authenticated)/admin/admins": [24,[3]],
		"/(authenticated)/admin/admins/create": [25,[3]],
		"/(authenticated)/admin/alumni-historical-programs": [26,[3]],
		"/(authenticated)/admin/alumni-historical-programs/add": [27,[3]],
		"/(authenticated)/admin/alumni-historical-programs/edit/[id]": [28,[3]],
		"/(authenticated)/admin/authentication/sso": [29,[3]],
		"/(authenticated)/admin/broadcast": [30,[3]],
		"/(authenticated)/admin/broadcast/history": [31,[3]],
		"/(authenticated)/admin/campus": [32,[3]],
		"/(authenticated)/admin/campus/create": [35,[3]],
		"/(authenticated)/admin/campus/[id]/delete": [33,[3]],
		"/(authenticated)/admin/campus/[id]/edit": [34,[3]],
		"/(authenticated)/admin/configuration/community-guidelines": [36,[3]],
		"/(authenticated)/admin/configuration/customize": [37,[3]],
		"/(authenticated)/admin/configuration/customize/public-pages": [38,[3]],
		"/(authenticated)/admin/configuration/settings": [39,[3]],
		"/(authenticated)/admin/content-moderation": [40,[3]],
		"/(authenticated)/admin/conversations-ambassador": [44,[3]],
		"/(authenticated)/admin/conversations": [41,[3]],
		"/(authenticated)/admin/conversations/conversation/[id]": [42,[3]],
		"/(authenticated)/admin/conversations/new": [43,[3]],
		"/(authenticated)/admin/dataforms-workflows": [51,[3]],
		"/(authenticated)/admin/dataforms-workflows/create": [55,[3]],
		"/(authenticated)/admin/dataforms-workflows/[id]/edit": [52,[3]],
		"/(authenticated)/admin/dataforms-workflows/[id]/preview": [53,[3]],
		"/(authenticated)/admin/dataforms-workflows/[id]/submissions": [54,[3]],
		"/(authenticated)/admin/dataforms": [45,[3]],
		"/(authenticated)/admin/dataforms/create": [50,[3]],
		"/(authenticated)/admin/dataforms/[id]/edit": [46,[3]],
		"/(authenticated)/admin/dataforms/[id]/preview": [47,[3]],
		"/(authenticated)/admin/dataforms/[id]/submissions": [48,[3]],
		"/(authenticated)/admin/dataforms/[id]/submissions/[submissionId]": [49,[3]],
		"/(authenticated)/admin/documents": [56,[3]],
		"/(authenticated)/admin/documents/create": [59,[3]],
		"/(authenticated)/admin/documents/[documentId]/delete": [57,[3]],
		"/(authenticated)/admin/documents/[documentId]/edit": [58,[3]],
		"/(authenticated)/admin/event-types/create/[eventType]": [60,[3]],
		"/(authenticated)/admin/event-types/edit/[id]": [61,[3]],
		"/(authenticated)/admin/(events-programs)/events": [7,[3]],
		"/(authenticated)/admin/(events-programs)/events/create": [8,[3]],
		"/(authenticated)/admin/(events-programs)/events/edit/[id]": [9,[3]],
		"/(authenticated)/admin/(events-programs)/events/invite/[id]": [10,[3]],
		"/(authenticated)/admin/(events-programs)/events/rsvps/[id]": [11,[3]],
		"/(authenticated)/admin/(events-programs)/events/types": [12,[3]],
		"/(authenticated)/admin/(events-programs)/events/view/[id]": [13,[3]],
		"/(authenticated)/admin/forum": [62,[3]],
		"/(authenticated)/admin/forum/create-topic": [64,[3]],
		"/(authenticated)/admin/forum/[topicId]": [63,[3]],
		"/(authenticated)/admin/groups": [65,[3]],
		"/(authenticated)/admin/groups/create": [66,[3]],
		"/(authenticated)/admin/groups/edit/[id]": [67,[3]],
		"/(authenticated)/admin/groups/view/[id]": [68,[3]],
		"/(authenticated)/admin/integrations": [69,[3]],
		"/(authenticated)/admin/integrations/sis": [70,[3]],
		"/(authenticated)/admin/integrations/sis/api-keys": [71,[3]],
		"/(authenticated)/admin/integrations/sis/csv-upload": [72,[3]],
		"/(authenticated)/admin/integrations/sis/mappings": [73,[3]],
		"/(authenticated)/admin/interests": [74,[3]],
		"/(authenticated)/admin/interests/create": [75,[3]],
		"/(authenticated)/admin/interests/edit/[id]": [76,[3]],
		"/(authenticated)/admin/interests/merge": [77,[3]],
		"/(authenticated)/admin/interests/replace": [78,[3]],
		"/(authenticated)/admin/locations": [79,[3]],
		"/(authenticated)/admin/locations/add": [80,[3]],
		"/(authenticated)/admin/locations/edit/[id]": [81,[3]],
		"/(authenticated)/admin/majors": [82,[3]],
		"/(authenticated)/admin/majors/create": [83,[3]],
		"/(authenticated)/admin/(events-programs)/programs": [14,[3]],
		"/(authenticated)/admin/(events-programs)/programs/create": [15,[3]],
		"/(authenticated)/admin/(events-programs)/programs/create/[programType]": [16,[3]],
		"/(authenticated)/admin/(events-programs)/programs/edit/[id]": [17,[3]],
		"/(authenticated)/admin/(events-programs)/programs/invite/[id]": [18,[3]],
		"/(authenticated)/admin/(events-programs)/programs/manage-access/[id]": [19,[3]],
		"/(authenticated)/admin/(events-programs)/programs/types": [20,[3]],
		"/(authenticated)/admin/(events-programs)/programs/view/[id]": [21,[3]],
		"/(authenticated)/admin/quick-links": [84,[3]],
		"/(authenticated)/admin/quick-links/create": [85,[3]],
		"/(authenticated)/admin/quick-links/edit/[id]": [86,[3]],
		"/(authenticated)/admin/reports": [87,[3]],
		"/(authenticated)/admin/reports/participants-abroad": [88,[3]],
		"/(authenticated)/admin/reports/past-programs": [89,[3]],
		"/(authenticated)/admin/reports/travel-advisories": [90,[3]],
		"/(authenticated)/admin/reports/upcoming-programs": [91,[3]],
		"/(authenticated)/admin/setup": [92,[3]],
		"/(authenticated)/admin/student-organizations": [93,[3]],
		"/(authenticated)/admin/student-organizations/create": [97,[3]],
		"/(authenticated)/admin/student-organizations/import-csv": [98,[3]],
		"/(authenticated)/admin/student-organizations/[id]": [94,[3]],
		"/(authenticated)/admin/student-organizations/[id]/delete": [95,[3]],
		"/(authenticated)/admin/student-organizations/[id]/edit": [96,[3]],
		"/(authenticated)/admin/study-abroad-advisors": [99,[3]],
		"/(authenticated)/admin/super": [100,[3]],
		"/(authenticated)/admin/super/features": [101,[3]],
		"/(authenticated)/admin/tasks": [102,[3]],
		"/(authenticated)/admin/tasks/create": [103,[3]],
		"/(authenticated)/admin/tasks/detail/[id]": [104,[3]],
		"/(authenticated)/admin/tasks/edit/[id]": [105,[3]],
		"/(authenticated)/admin/tasks/report/[id]": [106,[3]],
		"/(authenticated)/admin/tasks/submissions/[id]": [107,[3]],
		"/(authenticated)/admin/tutorials": [108,[3]],
		"/(authenticated)/admin/users": [109,[3]],
		"/(authenticated)/admin/users/allowlist": [110,[3]],
		"/(authenticated)/admin/users/allowlist/edit/[email]": [111,[3]],
		"/(authenticated)/admin/users/allowlist/list": [112,[3]],
		"/(authenticated)/admin/users/detail/[id]": [113,[3]],
		"/(authenticated)/app/alumni": [121,[2]],
		"/(authenticated)/app/ambassadors": [122,[2]],
		"/(authenticated)/app/chat": [123,[2]],
		"/(authenticated)/app/chat/conversation/[id]": [124,[2]],
		"/(authenticated)/app/chat/new": [125,[2]],
		"/(authenticated)/app/classmates": [126,[2]],
		"/(authenticated)/app/classmates/[id]": [127,[2]],
		"/(authenticated)/app/community-guidelines": [128,[2]],
		"/(authenticated)/app/conversation-partners": [129,[2]],
		"/(authenticated)/app/conversation-partners/[id]": [130,[2]],
		"/(authenticated)/app/dataforms": [131,[2]],
		"/(authenticated)/app/dataforms/available": [133,[2]],
		"/(authenticated)/app/dataforms/[id]": [132,[2]],
		"/(authenticated)/app/(events-programs)/events": [114,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]": [115,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]/register": [116,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]/withdraw-registration": [117,[2]],
		"/(authenticated)/app/faculty": [134,[2]],
		"/(authenticated)/app/forum": [135,[2]],
		"/(authenticated)/app/forum/[topicId]": [136,[2]],
		"/(authenticated)/app/forum/[topicId]/create-thread": [138,[2]],
		"/(authenticated)/app/forum/[topicId]/[threadId]": [137,[2]],
		"/(authenticated)/app/friendship-family": [139,[2]],
		"/(authenticated)/app/friendship-family/[id]": [140,[2]],
		"/(authenticated)/app/home": [141,[2]],
		"/(authenticated)/app/me/edit-profile": [142,[2]],
		"/(authenticated)/app/me/notifications": [143,[2]],
		"/(authenticated)/app/me/profile": [144,[2]],
		"/(authenticated)/app/micro-communities": [145,[2]],
		"/(authenticated)/app/micro-communities/[id]": [146,[2]],
		"/(authenticated)/app/my-account": [147,[2]],
		"/(authenticated)/app/my-account/change-password": [148,[2]],
		"/(authenticated)/app/my-account/deletion-request": [149,[2]],
		"/(authenticated)/app/my-account/verify-email": [150,[4]],
		"/(authenticated)/app/program-applications": [151,[2]],
		"/(authenticated)/app/program-applications/[id]": [152,[2]],
		"/(authenticated)/app/(events-programs)/programs": [118,[2]],
		"/(authenticated)/app/(events-programs)/programs/[id]": [119,[2]],
		"/(authenticated)/app/(events-programs)/programs/[id]/register": [120,[2]],
		"/(authenticated)/app/quick-links": [153,[2]],
		"/(authenticated)/app/staff": [154,[2]],
		"/(authenticated)/app/student-organizations": [155,[2]],
		"/(authenticated)/app/student-organizations/[id]": [156,[2]],
		"/(authenticated)/app/study-abroad-advisors": [157,[2]],
		"/(authenticated)/app/support": [158,[2]],
		"/(authenticated)/app/tasks": [159,[2]],
		"/(authenticated)/app/tasks/submissions": [161,[2]],
		"/(authenticated)/app/tasks/submission/[taskSubmissionId]": [160,[2]],
		"/(authenticated)/app/tasks/task/[taskId]": [162,[2]],
		"/(authenticated)/conversation-partner": [163,[2]],
		"/(authenticated)/conversation-partner/setup-profile": [164,[2]],
		"/(authenticated)/friendship-family": [165,[2]],
		"/(authenticated)/friendship-family/setup-profile": [166,[2]],
		"/(public)/logout": [167],
		"/(public)/public": [168,[5]],
		"/(public)/public/community-guidelines": [169,[5]],
		"/(public)/public/forgot-password": [170,[5]],
		"/(public)/public/forgot-password/success": [171,[5]],
		"/(public)/public/internal/ota-device-id": [172,[5]],
		"/(public)/public/login": [173,[5]],
		"/(public)/public/mobile-app": [174,[5]],
		"/(public)/public/register": [175,[5]],
		"/(public)/public/register/admitted-student": [176,[5]],
		"/(public)/public/register/conversation-partner": [177,[5]],
		"/(public)/public/register/friendship-family": [178,[5]],
		"/(public)/public/register/prospective-student": [179,[5]],
		"/(public)/public/reset-password": [180,[5]],
		"/(public)/public/reset-password/success": [181,[5]],
		"/(public)/public/sso/saml2/campcore": [182,[5]],
		"/(public)/public/sso/saml2/campcore/assert": [183,[5]],
		"/(public)/public/sso/saml2/institution": [184,[5]],
		"/(public)/public/sso/saml2/institution/assert": [185,[5]],
		"/[...rest]": [186]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';